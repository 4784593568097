import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

class DataService {
  static async getData(token, fromDate, toDate, type) {
    const from = new Date(Date.parse(fromDate)).toISOString().split('T')[0];
    const to = new Date(Date.parse(toDate)).toISOString().split('T')[0];

    const response = await axios.get(
      `${API_URL}/export/?fromDate=${encodeURI(from)}&toDate=${encodeURI(to)}&type=${type}`,
      { headers: { Authorization: `${token}` } }
    );

    return response.data;
  }
}

export default DataService;