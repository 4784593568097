import React, { Component } from 'react';
import UserService from '../services/UserService';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            error: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        const { handleLogin, history } = this.props;
        const { username, password } = this.state;

        try {
            const token = await UserService.login(username, password);
            handleLogin(token);
            history.push('/export');
        } catch (error) {
            this.setState({ error: error.message });
        }
    }

    render() {
        return (
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <form onSubmit={this.handleSubmit} className="mt-5">
                        <h2 className="mb-4">Login</h2>
                        {this.state.error && <div className="alert alert-danger">{this.state.error}</div>}
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input
                                type="text"
                                className="form-control"
                                id="username"
                                name="username"
                                value={this.state.username}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">
                            Login
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default Login;