import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

class UserService {
    static async login(username, password) {
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);

        return fetch(`${API_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: params
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then((data) => {
            return data.token;
        });
    }

    static async logout() {
        const response = await axios.post(`${API_URL}/auth/logout`);
        return response.data;
    }
}

export default UserService;