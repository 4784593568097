import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DataService from '../services/DataService';
import { utils, writeFile } from 'xlsx';

class Export extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fromDate: '',
      toDate: ''
    };

    this.handleExport = this.handleExport.bind(this);
    this.handleFromDateChange = this.handleFromDateChange.bind(this);
    this.handleToDateChange = this.handleToDateChange.bind(this);
  }

  handleFromDateChange(date) {
    this.setState({
      fromDate:date
    });
  }

  handleToDateChange(date) {
    this.setState({
      toDate: date
    });
  }

  resizeColumns(json) {
    const maxLengths = json.reduce((acc, row) => {
      Object.keys(row).forEach((key) => {
        if (row[key]) {
          const columnLength = row[key].toString().length;
          acc[key] = acc[key] || 0;
          acc[key] = Math.max(columnLength, acc[key]);
        }
      });
      return acc;
    }, {});

    const columnWidths = Object.keys(maxLengths).map((key) => {
      return { wch: maxLengths[key] };
    });
    
    const wscols = columnWidths.map((width, index) => {
      return { width: width.wch + 1 };
    });

    return wscols;
  }

  async handleExport(event) {
    event.preventDefault();
    const { token } = this.props;
    const { fromDate, toDate } = this.state;
    const sheetTitles = {aux: 'Auxiliary Power', signal: 'Signal'};
    const dataTypes = ['aux', 'signal'];
    const workbook = utils.book_new();

    for (const typeIndex in dataTypes) {
      const type = dataTypes[typeIndex];
      const json = await DataService.getData(token, fromDate, toDate, type);
      const worksheet = utils.json_to_sheet(json);
      utils.book_append_sheet(workbook, worksheet, type);
      const wscols = this.resizeColumns(json);
      worksheet['!cols'] = wscols;
    }

    console.log(workbook.SheetNames);

    writeFile(workbook, 'data.xlsx');
  }

  render() {
    return (
        <div className="row justify-content-center">
        <div className="col-md-4">
          <form onSubmit={this.handleExport} className="mt-5">
            <h2 className="mb-4">Export Data</h2>
            <div className="form-group">
              <label htmlFor="fromDate">From Date</label>
              <DatePicker
                selected={this.state.fromDate}
                onChange={this.handleFromDateChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="toDate">To Date</label>
              <DatePicker
                selected={this.state.toDate}
                onChange={this.handleToDateChange}
                className="form-control"
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Export
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default Export;