import React from 'react';
import { withRouter } from 'react-router-dom';
import Login from './components/Login';
import Export from './components/Export';
import Navbar from './components/Navbar';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      token: null
    };
    this.logout = this.logout.bind(this);
  }

  handleLogin = (token) => {
    this.setState({ token: token });
    this.setState({ isLoggedIn: true });
    this.props.history.push('/export'); 
  };

  logout() {
    this.setState({
      isLoggedIn: false,
      token: null,
    });
    this.props.history.push('/'); 
  }

  render() {
    const { isLoggedIn, token } = this.state;
    return (
      <div>
      {!token ?
         <Login
         login={this.login}
         isLoggedIn={isLoggedIn}
         token={token}
         handleLogin={this.handleLogin}
       /> :
       <div>
         <Navbar logout={this.logout} />
         <Export token={token} />
      </div>
      }
    </div>
    );
  }
}

export default withRouter(App);
